import React from "react";

const Policy: React.FC = () => {
    return (
        <div>
            <embed src="/resources/b949bd7a44043bdcc856d393d20086az.pdf" width="100%" height="1400" type="application/pdf" />
        </div>

    );
};

export default Policy;
